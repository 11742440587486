<template>
  <div class="listVuexSearch md-layout-item md-small-size-100 md-size-150">
    <div
      class="md-layout-item md-small-size-100 md-size-100"
      v-show="showSelecionou"
    >
      <p>
        você selecionou - nome: {{ servidorPensionistaClicado.nomeCompleto }} -
        cpf: {{ servidorPensionistaClicado.cpf }}
      </p>
    </div>
    <div>
      <div class="md-layout-item md-small-size-100 md-size-100">
        <md-field>
          <label>{{ labelPesquisar }}</label>
          <md-input
            @keyup="searchChange"
            type="text"
            placeholder="Pesquisar"
          ></md-input>
        </md-field>
      </div>
    </div>
    <div class="empty__message centered" v-if="!items.length">
      Por favor aguarde gerando...
    </div>
    <div
      class="empty__message centered"
      v-if="searchText && !results_array.length"
    >
      Sem resultado
    </div>
    <RecycleScroller class="scroller" :items="items" :item-size="125">
      <template slot-scope="props" v-if="items.length">
        <contact-detail
          v-model="servidorPensionistaClicado"
          :key="props.itemKey"
          :id="props.item.id"
          :nomeCompleto="props.item.nomeCompleto"
          :cpf="props.item.cpf"
          :siape="props.item.siape"
          :search="searchText"
        >
        </contact-detail>
      </template>
    </RecycleScroller>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import ContactDetail from "@/components/ContactDetail";
import {
  siapeServidorMask,
  siapePensionistaMask
} from "@/pages/Dashboard/js/siapeMask";

export default {
  props: {
    items_prop: Array,
    nomePapelSelecionado: String
  },
  components: {
    ContactDetail
  },
  data() {
    return {
      showSelecionou: true,
      labelPesquisar: "",
      items: [],
      results_array: [],
      searchText: "",
      servidorPensionistaClicado: ""
    };
  },
  beforeMount() {
    this.firstLoad();
    //console.log(this.items_prop);
  },
  computed: {
    results() {
      return this.results_array;
    }
  },
  methods: {
    firstLoad: function() {
      //console.log(" firstLoad after: ", this.items_prop);
      if (this.nomePapelSelecionado === "PENSIONISTA") {
        this.items_prop.forEach(obj => {
          this.renameProperty(obj, "idPensionista", "id");
          this.renameProperty(obj, "cpfPensionista", "cpf");
          this.renameProperty(obj, "siapePensionista", "siape");
          obj["siape"] = this.siapePensionistaMaskCall(obj["siape"]);
        });
      } else if (this.nomePapelSelecionado === "SERVIDOR") {
        this.labelPesquisar = "pesquisar Pensionista ou Servidor";
        this.items_prop.forEach(obj => {
          this.renameProperty(obj, "idServidor", "id");
          this.renameProperty(obj, "cpfServidor", "cpf");
          this.renameProperty(obj, "siapeServidor", "siape");
          obj["siape"] = this.siapeServidorMaskCall(obj["siape"]);
        });
      } else if (this.nomePapelSelecionado === "SERVIDOR_PENSIONISTA") {
        this.labelPesquisar = "pesquisar Servidor";
        this.showSelecionou = false;
        this.items_prop.forEach(obj => {
          this.renameProperty(obj, "idServidor", "id");
          this.renameProperty(obj, "cpfServidor", "cpf");
          this.renameProperty(obj, "siapeServidor", "siape");
        });
      }
      this.items = this.items_prop;
      return null;
    },
    renameProperty(obj, oldName, newName) {
      obj[newName] = obj[oldName];
      delete obj[oldName];
    },
    searchChange(e) {
      this.searchText = e.target.value;
      this.searchContacts(this.searchText);
    },
    searchContacts(value) {
      let results = this.items_prop;
      this.fuseSearch = new Fuse(results, {
        keys: ["cpf", "nomeCompleto", "siape"],
        threshold: 0.2
      });
      results = this.fuseSearch.search(value);
      if (results.length === 0) {
        this.items = this.items_prop;
        this.results_array = [];
      } else {
        this.items = results;
        this.results_array = results;
      }
      this.fuseSearch = null;
    },
    siapeServidorMaskCall(value) {
      value =
        value !== "" && value !== null && typeof value !== "undefined"
          ? siapeServidorMask(value)
          : null;
      return value;
    },
    siapePensionistaMaskCall(value) {
      value =
        value !== "" && value !== null && typeof value !== "undefined"
          ? siapePensionistaMask(value)
          : null;
      return value;
    }
  }
};
</script>

<style lang="scss" scoped>
.listVuexSearch {
  width: 100%;
  height: 100%;
  position: relative;
  .inputs__container {
    display: flex;
    justify-content: center;
    align-items: center;
    .searchbar {
      flex-grow: 1;
    }
  }
  .centered {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    font-weight: 500;
    opacity: 0.5;
  }
  .loading__icon {
    width: 50px;
    filter: invert(100%);
    margin-top: 10px;
  }
  .scroller {
    background-color: #fbfbfb;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    height: 250px;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
}
</style>
