export const PARENTESCO = [
  { id: 1, parentesco: "PAI" },
  { id: 2, parentesco: "MAE" },
  { id: 3, parentesco: "FILHO" },
  { id: 4, parentesco: "FILHA" },
  { id: 5, parentesco: "IRMAO" },
  { id: 6, parentesco: "IRMA" },
  { id: 7, parentesco: "AVO" },
  { id: 9, parentesco: "NETO" },
  { id: 10, parentesco: "NETA" },
  { id: 11, parentesco: "TIO" },
  { id: 12, parentesco: "TIA" },
  { id: 13, parentesco: "SOBRINHO" },
  { id: 14, parentesco: "SOBRINHA" },
  { id: 15, parentesco: "BISAVO" },
  { id: 17, parentesco: "BISNETO" },
  { id: 18, parentesco: "BISNETA" },
  { id: 19, parentesco: "SOGRO" },
  { id: 20, parentesco: "SOGRA" },
  { id: 21, parentesco: "GENRO" },
  { id: 22, parentesco: "NORA" },
  { id: 23, parentesco: "PADRASTO" },
  { id: 24, parentesco: "MADRASTA" },
  { id: 25, parentesco: "ENTEADO" },
  { id: 26, parentesco: "ENTEADA" },
  { id: 27, parentesco: "CUNHADOS" },
  { id: 28, parentesco: "CUNHADA" }
];
