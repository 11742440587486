<template>
  <md-table v-model="pensao" @md-selected="onSelect">
    <div v-if="!isFetching">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div style="padding-top:15px;display:inline-block;">
            <span>
              <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
                Pensionista
              </h5></span
            >
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="Servidor"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="servidorSelect">Selecionar Servidor</label>
                  <md-select
                    id="selectServidorOptionField"
                    v-if="!isFetching"
                    v-model="servidorSelect"
                    name="servidorSelect"
                    @md-opened="sizeMdSelectCall()"
                    @md-selected="selectedServidor"
                  >
                    <md-option
                      v-for="item in servidorSelectOption"
                      :key="item.idServidor"
                      :label="item.nomeCompleto"
                      :value="item.idServidor"
                    >
                      CPF:
                      {{ callCpfMask(item.cpfServidor) }} ||
                      {{ item.nomeCompleto }} || Siape:
                      {{ item.siapeServidor }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="switch-div">
              <md-button
                type="button"
                v-show="showButton"
                class="md-raised md-success mt-3"
                @click="addServidor(pensionistaSelected)"
              >
                ADICIONAR SERVIDOR
              </md-button>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100">
              <span
                class="block text-xs absolute bottom-0 left-0"
                v-if="!isFetching"
                >Servidor Selecionado: {{ labelServidorSelect }}
              </span>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-33">
              <div class="list-modal">
                <list-contacts
                  :key="componentKeyToRerender"
                  v-show="showContactList"
                  :searchText="search"
                  :items_prop="servidoresFromEntidade"
                  :nomePapelSelecionado="nomePapelSelecionado"
                ></list-contacts>
              </div>
            </div>
          </div>
          <span>
            <h5
              class="title"
              style="font-weight:350;padding-bottom: 5px;padding-top: 10px;"
            >
              Pensionista
            </h5>
          </span>
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="servidorPensionista.siapePensionista"
                :rules="{
                  regex: /^[0-9][0-9][0-9][0-9][0-9][1-9]{3}$/,
                  min: 8,
                  max: 8,
                  required: true,
                  numeric: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Siape Pensionista</label>
                  <md-input
                    v-model="servidorPensionista.siapePensionista"
                    type="text"
                    :maxlength="9"
                    :key="componentKeyToRerender2"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Siape deve ter 8 digitos, ao menos 3 diferente de 0." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-10">
              <ValidationProvider
                name="servidorPensionista.siapePensionistaDv"
                :rules="{
                  regex: /^\d{1}$/,
                  min: 1,
                  max: 1
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>DV Siape</label>
                  <md-input
                    v-model="servidorPensionista.siapePensionistaDv"
                    type="text"
                    :maxlength="1"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "DV Siape deve ter 1 digito." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                :name="servidorPensionista.parentesco"
                :rules="{
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="parentescoSelect">Parentesco</label>
                  <md-select
                    v-model="servidorPensionista.parentesco"
                    name="parentescoSelect"
                  >
                    <md-option
                      v-for="item in parentescoSelectOption"
                      :key="item.id"
                      :label="item.parentesco"
                      :value="item.parentesco"
                    >
                      {{ item.parentesco }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Unidade Pagadora
            </h5>
          </span>
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="servidorPensionista.upag"
                :rules="{
                  regex: /^\d{9}$/,
                  min: 9,
                  max: 9,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Unidade Pagadora</label>
                  <md-input
                    v-model="servidorPensionista.upag"
                    type="text"
                    :maxlength="9"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Unidade Pagadora deve ter 9 digitos." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="servidorPensionista.upagDescricao"
                :rules="{
                  min: 3,
                  max: 100,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Unidade Pagadora Descrição</label>
                  <md-input
                    v-model="servidorPensionista.upagDescricao"
                    type="text"
                    :maxlength="100"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="servidorPensionista.siglaUpag"
                :rules="{
                  min: 3,
                  max: 20,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Sigla Unidade Pagadora</label>
                  <md-input
                    v-model="servidorPensionista.siglaUpag"
                    type="text"
                    :maxlength="20"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="servidorPensionista.ufUpag"
                :rules="{
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="ufUpagSelect">UF da Upag</label>
                  <md-select
                    id="selectOptionFieldUfUpag"
                    v-model="ufUpagSelect"
                    name="ufUpagSelect"
                    @md-opened="mdSelectScrollResizeCall()"
                    @md-selected="selectUfUpag"
                  >
                    <md-option
                      v-for="item in estados"
                      :key="item.shortname"
                      :label="item.name"
                      :value="item.shortname"
                    >
                      {{ item.shortname }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Pensão
            </h5>
          </span>
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="servidorPensionista.tipoPensao"
                :rules="{
                  regex: /^(?:\d{1}|\d{2})$/,
                  min: 1,
                  max: 2,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Tipo Pensão</label>
                  <md-input
                    v-model="servidorPensionista.tipoPensao"
                    type="text"
                    :maxlength="2"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Tipo Pensão deve ter de 1 a 2 digitos." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="servidorPensionista.ocorrenciaPensao"
                :rules="{
                  min: 3,
                  max: 50,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Ocorrência Pensão</label>
                  <md-input
                    v-model="servidorPensionista.ocorrenciaPensao"
                    type="text"
                    :maxlength="50"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="servidorPensionista.naturezaPensao"
                :rules="{
                  regex: /^(?:\d{1}|\d{2})$/,
                  min: 1,
                  max: 2,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Natureza Pensão</label>
                  <md-input
                    v-model="servidorPensionista.naturezaPensao"
                    type="text"
                    :maxlength="2"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Natureza Pensão deve ter de 1 a 2 digitos." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="servidorPensionista.numeroProcessoPensao"
                :rules="{
                  numeric: true,
                  min: 1,
                  max: 20,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Número Processo Pensão</label>
                  <md-input
                    v-model="servidorPensionista.numeroProcessoPensao"
                    type="text"
                    :maxlength="20"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100"></div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="servidorPensionista.dataInicioPensao"
                :rules="{
                  regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                  min: 10,
                  max: 10,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Data Inicio Pensão</label>
                  <md-input
                    v-model="servidorPensionista.dataInicioPensao"
                    type="text"
                    :maxlength="10"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Data deve ter formato DD/MM/AAAA" }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="servidorPensionista.dataFimPensao"
                :rules="{
                  regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                  min: 10,
                  max: 10,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Data Fim Pensão</label>
                  <md-input
                    v-model="servidorPensionista.dataFimPensao"
                    type="text"
                    :maxlength="10"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Data deve ter formato DD/MM/AAAA" }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100"></div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="servidorPensionista.codExclusaoPensionista"
                :rules="{
                  regex: /^(?:\d{1}|\d{2}|\d{3})$/,
                  min: 1,
                  max: 3
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Código Exclusão</label>
                  <md-input
                    v-model="servidorPensionista.codExclusaoPensionista"
                    type="text"
                    :maxlength="3"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Código Exclusão deve ter de 1 a 3 digitos." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="servidorPensionista.grupoExcPensionista"
                :rules="{
                  numeric: true,
                  min: 1,
                  max: 2
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Grupo Exclusão</label>
                  <md-input
                    v-model="servidorPensionista.grupoExcPensionista"
                    type="text"
                    :maxlength="2"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="servidorPensionista.dataExclusaoPensionista"
                :rules="{
                  regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                  min: 10,
                  max: 10
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Data Exclusão</label>
                  <md-input
                    v-model="servidorPensionista.dataExclusaoPensionista"
                    type="text"
                    :maxlength="10"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Data deve ter formato DD/MM/AAAA" }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="servidorPensionista.ocorrenciaExclusaoPensionista"
                :rules="{
                  min: 3,
                  max: 50
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Ocorrência Exclusão</label>
                  <md-input
                    v-model="servidorPensionista.ocorrenciaExclusaoPensionista"
                    type="text"
                    :maxlength="50"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div
              style="display:inline-block;"
              class="md-layout-item md-small-size-100 md-size-15"
            >
              <ValidationProvider
                name="servidorPensionista.quotaParteEspolio"
                :rules="{
                  required: true,
                  regex: /^(?!00)\d+(\.\d)?\d*$/,
                  min: 1,
                  max: 8
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Quota Parte %</label>
                  <md-input
                    v-model="servidorPensionista.quotaParteEspolio"
                    type="text"
                  ></md-input>
                  <div>&nbsp;&nbsp;%</div></md-field
                >
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{
                    "Fator deve ter formato decimal ou inteiro 99.99 ou 99 até 7 digitos."
                  }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-100 text-right">
              <md-button
                type="submit"
                v-show="showButton"
                class="md-raised md-success mt-4"
              >
                CRIAR
              </md-button>
              &nbsp;
              <md-button
                @click="validarProximo()"
                class="md-raised md-success mt-4"
              >
                PROXIMO >
              </md-button>
              &nbsp;
              <md-button
                type="button"
                v-show="showButton"
                class="md-raised md-danger mt-4"
                @click="
                  removeServidorPesionista(servidorPensionista.servidorPublico)
                "
              >
                DELETAR
              </md-button>
              &nbsp;
              <md-button
                type="button"
                v-show="showButton"
                class="md-raised mt-4"
                @click="cancelarButton"
              >
                CANCELAR
              </md-button>
              <span>&nbsp;</span>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </md-table>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import { NavTabsCard } from "@/components";
import Vue from "vue";
import Swal from "sweetalert2";
import { dataMask } from "@/pages/Dashboard/js/dataMask";
import { ESTADOS } from "@/pages/Dashboard/js/estados";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import { PARENTESCO } from "@/pages/Dashboard/js/parentesco";
import mdSelectScrollResize from "@/pages/Dashboard/js/mdSelectScrollResize";
import ListContacts from "@/components/ListContacts";
import { cpfMask } from "@/pages/Dashboard/js/cpfMask";
import { checkAllResponsesPensionista } from "../js/checkAllResponsesPensionista";
import { siapePensionistaMask } from "@/pages/Dashboard/js/siapeMask";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  if (key === "regex") {
    pt_br.messages.regex = "Campo com formato inválido.";
  }
}
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

const sizeMdSelect = function() {
  let elmnt = document.getElementsByClassName(
    "md-select-menu md-menu-content-bottom-start md-menu-content-small md-menu-content md-theme-default"
  );
  for (let i = 0; i < elmnt.length; i++) {
    elmnt[i].style.maxWidth = "400px";
  }
};

export default {
  /*props: {
    id: {
      type: String
    },
    isEdition: {
      type: Boolean
    }
  },*/
  components: { ListContacts },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("pensionista", {
      pensionistaSelected: "pensionistaState"
    }),
    ...mapGetters("servidor_pensionista", {
      servidoresFromPensionistaState: "getServidoresFromPensionistaState",
      servidorPensionistaState: "getServidorPensionistaState",
      servidoresPensionistasState: "getServidoresPensionistasState",
      servidorOrPensionistaSelected: "getServidorOrPensionistaSelectedState"
    }),
    user_profile_state: state => state.user_profile
  },
  data() {
    return {
      classicModal: false,
      classicModalListContacts: false,
      componentKeyToRerender: 0,
      showContactList: false,
      isRequiredIdServidorOrPensionista: true,
      idServPen: null,
      idServidor: null,
      selectedPensionistaId: this.id,
      pensao: null,
      estados: ESTADOS,
      editionMode: false,
      isFetching: true,
      showButton: true,
      isDisabledEdition: true,
      ufUpagSelect: "",
      mensagem: "",
      search: "",
      parentescoSelect: "",
      parentescoSelectOption: PARENTESCO,
      servidoresFromEntidade: [],
      nomePapelSelecionado: "SERVIDOR_PENSIONISTA",
      servidorSelect: "",
      servidorSelectOption: [],
      labelServidorSelect: "",
      servidorPensionista: {
        idServPen: null,
        pensionista: this.id,
        servidorPublico: null,
        idEntidade: null,
        nomeEntidade: null,
        siapePensionista: null,
        siapePensionistaDv: null,
        parentesco: null,
        upag: null,
        upagDescricao: null,
        siglaUpag: null,
        ufUpag: null,
        tipoPensao: null,
        ocorrenciaPensao: null,
        naturezaPensao: null,
        numeroProcessoPensao: null,
        dataInicioPensao: null,
        dataFimPensao: null,
        codExclusaoPensionista: null,
        grupoExcPensionista: null,
        ocorrenciaExclusaoPensionista: null,
        quotaParteEspolio: null,
        dataExclusaoPensionista: null
      },
      papel: "",
      servidoresPensionistas: []
    };
  },
  beforeMount() {
    this.getPapel();
    this.loadPensionista();
  },
  methods: {
    ...mapActions("papel", ["ActionGetPapelAll"]),
    ...mapActions("pensionista", [
      "ActionSetPENSIONISTA",
      "ActionGetPensionistaById",
      "ActionPUTPensionista",
      "ActionSubmitPensionistaAllData"
    ]),
    ...mapActions("servidor_publico", [
      "ActionGetServidorPublicoByEntidade",
      "ActionGetServidorPublicoById"
    ]),
    ...mapActions("entidade", ["ActionGetEntidadeById"]),
    ...mapActions("servidor_pensionista", [
      "ActionGetServidorFromPen",
      "ActionSetServidoresFromPensionista",
      "ActionGetServidorPensionistaById",
      "ActionSetServidorPensionista",
      "ActionSetServidoresPensionistas",
      "ActionPUTServidorPensionista",
      "ActionDELETEServidorPensionistaById",
      "ActionPOSTServidorPensionista"
    ]),
    forceRerender() {
      this.componentKeyToRerender += 1;
    },
    classicModalHide: function() {
      this.classicModal = false;
    },
    callCpfMask(value) {
      if (value != null || value != null) {
        return cpfMask(value.toString());
      }
    },
    sizeMdSelectCall: function() {
      sizeMdSelect();
    },
    validaForm() {
      let checkerror = true;
      this.$refs.observer.validate();
      return this.delay(1000).then(() => {
        if (this.$refs.observer.errors) {
          checkerror = Object.keys(this.$refs.observer.errors).some(key => {
            let checkerrorArray =
              this.$refs.observer.errors[key].length > 0 ? true : false;
            return checkerrorArray;
          });
        }
        return checkerror;
      });
    },
    async loadservidorPensionista(idServidor) {
      if (idServidor === null) {
        if (this.servidoresPensionistasState.length > 0) {
          Object.keys(this.servidoresPensionistasState).map(key => {
            return this.servidoresPensionistas.push(
              Vue.util.extend({}, this.servidoresPensionistasState[key])
            );
          });

          this.servidorPensionista = this.servidoresPensionistas[0];
          this.ActionSetServidorPensionista(this.servidorPensionista);

          this.servidorPensionista.idEntidade = this.pensionistaSelected.idEntidade;
          this.servidorPensionista.nomeEntidade = this.pensionistaSelected.nomeEntidade;

          if (this.servidorPensionistaState.siapePensionista) {
            this.servidorPensionista.siapePensionista =
              this.servidorPensionistaState.siapePensionista === null
                ? null
                : this.servidorPensionistaState.siapePensionista.trim();
          }

          if (this.servidorPensionistaState.siapePensionistaDv) {
            this.servidorPensionista.siapePensionistaDv =
              this.servidorPensionistaState.siapePensionistaDv === null
                ? null
                : this.servidorPensionistaState.siapePensionistaDv
                    .toString()
                    .trim();
          }

          if (this.servidorPensionistaState.upag) {
            this.servidorPensionista.upag =
              this.servidorPensionistaState.upag === null
                ? null
                : this.servidorPensionistaState.upag.toString().trim();
          }

          if (this.servidorPensionistaState.parentesco) {
            let parentesco = this.servidorPensionistaState.parentesco;
            if (parentesco !== null) {
              let index = -1;
              let resultItem = this.parentescoSelectOption.find(function(
                item,
                i
              ) {
                if (item.parentesco === parentesco) {
                  index = i;
                  return item;
                }
              });
              this.servidorPensionista.parentesco = resultItem.parentesco;
              this.parentescoSelect = resultItem.parentesco;
            }
          }

          if (this.servidorPensionistaState.upagDescricao) {
            this.servidorPensionista.upagDescricao =
              this.servidorPensionistaState.upagDescricao === null
                ? null
                : this.servidorPensionistaState.upagDescricao.toString().trim();
          }
          if (this.servidorPensionistaState.siglaUpag) {
            this.servidorPensionista.siglaUpag =
              this.servidorPensionistaState.siglaUpag === null
                ? null
                : this.servidorPensionistaState.siglaUpag.toString().trim();
          }

          if (this.servidorPensionistaState.ufUpag) {
            let ufUpag = this.servidorPensionistaState.ufUpag;
            let index = -1;
            let resultItem = this.estados.find(function(item, i) {
              if (item.shortname === ufUpag) {
                index = i;
                return item;
              }
            });
            this.servidorPensionista.ufUpag = resultItem.shortname;
            this.ufUpagSelect = resultItem.shortname;
          }

          if (this.servidorPensionistaState.tipoPensao) {
            this.servidorPensionista.tipoPensao =
              this.servidorPensionistaState.tipoPensao === null
                ? null
                : this.servidorPensionistaState.tipoPensao.toString().trim();
          }
          if (this.servidorPensionistaState.naturezaPensao) {
            this.servidorPensionista.naturezaPensao =
              this.servidorPensionistaState.naturezaPensao === null
                ? null
                : this.servidorPensionistaState.naturezaPensao
                    .toString()
                    .trim();
          }

          if (this.servidorPensionistaState.ocorrenciaPensao) {
            this.servidorPensionista.ocorrenciaPensao =
              this.servidorPensionistaState.ocorrenciaPensao === null
                ? null
                : this.servidorPensionistaState.ocorrenciaPensao
                    .toString()
                    .trim();
          }

          if (this.servidorPensionistaState.numeroProcessoPensao) {
            this.servidorPensionista.numeroProcessoPensao =
              this.servidorPensionistaState.numeroProcessoPensao === null
                ? null
                : this.servidorPensionistaState.numeroProcessoPensao
                    .toString()
                    .trim();
          }

          if (this.servidorPensionistaState.dataInicioPensao) {
            this.servidorPensionista.dataInicioPensao =
              this.servidorPensionistaState.dataInicioPensao === null
                ? null
                : this.servidorPensionistaState.dataInicioPensao
                    .toString()
                    .trim();
          }

          if (this.servidorPensionistaState.dataFimPensao) {
            this.servidorPensionista.dataFimPensao =
              this.servidorPensionistaState.dataFimPensao === null
                ? null
                : this.servidorPensionistaState.dataFimPensao.toString().trim();
          }

          if (this.servidorPensionistaState.codExclusaoPensionista) {
            this.servidorPensionista.codExclusaoPensionista =
              this.servidorPensionistaState.codExclusaoPensionista === null
                ? null
                : this.servidorPensionistaState.codExclusaoPensionista
                    .toString()
                    .trim();
          }

          if (this.servidorPensionistaState.ocorrenciaExclusaoPensionista) {
            this.servidorPensionista.ocorrenciaExclusaoPensionista =
              this.servidorPensionistaState.ocorrenciaExclusaoPensionista ===
              null
                ? null
                : this.servidorPensionistaState.ocorrenciaExclusaoPensionista
                    .toString()
                    .trim();
          }

          if (this.servidorPensionistaState.dataExclusaoPensionista) {
            this.servidorPensionista.dataExclusaoPensionista =
              this.servidorPensionistaState.dataExclusaoPensionista === null
                ? null
                : this.servidorPensionistaState.dataExclusaoPensionista
                    .toString()
                    .trim();
          }

          if (this.servidorPensionistaState.quotaParteEspolio) {
            this.servidorPensionista.quotaParteEspolio =
              this.servidorPensionistaState.quotaParteEspolio === null
                ? null
                : this.servidorPensionistaState.quotaParteEspolio
                    .toString()
                    .trim();
          }

          this.servidorSelectOption = this.servidoresFromPensionistaState;
          this.servidorSelect = this.servidorPensionista.servidorPublico;
          this.getServidorSelectedLabel(
            this.servidorPensionista.servidorPublico
          );
        } else {
          this.ActionSetServidorPensionista(this.servidorPensionista);
        }
      } else {
        let index = -1;
        let resultItem = this.servidoresPensionistas.find(function(item, i) {
          if (item.servidorPublico === idServidor) {
            index = i;
            return item;
          }
        });
        this.servidorPensionista = resultItem;
        this.ActionSetServidorPensionista(this.servidorPensionista);

        this.servidorPensionista.idEntidade = this.pensionistaSelected.idEntidade;
        this.servidorPensionista.nomeEntidade = this.pensionistaSelected.nomeEntidade;

        if (this.servidorPensionistaState.siapePensionista) {
          this.servidorPensionista.siapePensionista =
            this.servidorPensionistaState.siapePensionista === null
              ? null
              : this.servidorPensionistaState.siapePensionista.trim();
        }

        if (this.servidorPensionistaState.siapePensionistaDv) {
          this.servidorPensionista.siapePensionistaDv =
            this.servidorPensionistaState.siapePensionistaDv === null
              ? null
              : this.servidorPensionistaState.siapePensionistaDv
                  .toString()
                  .trim();
        }

        if (this.servidorPensionistaState.upag) {
          this.servidorPensionista.upag =
            this.servidorPensionistaState.upag === null
              ? null
              : this.servidorPensionistaState.upag.toString().trim();
        }

        if (this.servidorPensionistaState.parentesco) {
          let parentesco = this.servidorPensionistaState.parentesco;
          if (parentesco !== null) {
            let index = -1;
            let resultItem = this.parentescoSelectOption.find(function(
              item,
              i
            ) {
              if (item.parentesco === parentesco) {
                index = i;
                return item;
              }
            });
            this.servidorPensionista.parentesco = resultItem.parentesco;
            this.parentescoSelect = resultItem.parentesco;
          }
        }

        if (this.servidorPensionistaState.upagDescricao) {
          this.servidorPensionista.upagDescricao =
            this.servidorPensionistaState.upagDescricao === null
              ? null
              : this.servidorPensionistaState.upagDescricao.toString().trim();
        }
        if (this.servidorPensionistaState.siglaUpag) {
          this.servidorPensionista.siglaUpag =
            this.servidorPensionistaState.siglaUpag === null
              ? null
              : this.servidorPensionistaState.siglaUpag.toString().trim();
        }

        if (this.servidorPensionistaState.ufUpag) {
          let ufUpag = this.servidorPensionistaState.ufUpag;
          let index = -1;
          let resultItem = this.estados.find(function(item, i) {
            if (item.shortname === ufUpag) {
              index = i;
              return item;
            }
          });
          this.servidorPensionista.ufUpag = resultItem.shortname;
          this.ufUpagSelect = resultItem.shortname;
        }

        if (this.servidorPensionistaState.tipoPensao) {
          this.servidorPensionista.tipoPensao =
            this.servidorPensionistaState.tipoPensao === null
              ? null
              : this.servidorPensionistaState.tipoPensao.toString().trim();
        }
        if (this.servidorPensionistaState.naturezaPensao) {
          this.servidorPensionista.naturezaPensao =
            this.servidorPensionistaState.naturezaPensao === null
              ? null
              : this.servidorPensionistaState.naturezaPensao.toString().trim();
        }

        if (this.servidorPensionistaState.ocorrenciaPensao) {
          this.servidorPensionista.ocorrenciaPensao =
            this.servidorPensionistaState.ocorrenciaPensao === null
              ? null
              : this.servidorPensionistaState.ocorrenciaPensao
                  .toString()
                  .trim();
        }

        if (this.servidorPensionistaState.numeroProcessoPensao) {
          this.servidorPensionista.numeroProcessoPensao =
            this.servidorPensionistaState.numeroProcessoPensao === null
              ? null
              : this.servidorPensionistaState.numeroProcessoPensao
                  .toString()
                  .trim();
        }

        if (this.servidorPensionistaState.dataInicioPensao) {
          this.servidorPensionista.dataInicioPensao =
            this.servidorPensionistaState.dataInicioPensao === null
              ? null
              : this.servidorPensionistaState.dataInicioPensao
                  .toString()
                  .trim();
        }

        if (this.servidorPensionistaState.dataFimPensao) {
          this.servidorPensionista.dataFimPensao =
            this.servidorPensionistaState.dataFimPensao === null
              ? null
              : this.servidorPensionistaState.dataFimPensao.toString().trim();
        }

        if (this.servidorPensionistaState.codExclusaoPensionista) {
          this.servidorPensionista.codExclusaoPensionista =
            this.servidorPensionistaState.codExclusaoPensionista === null
              ? null
              : this.servidorPensionistaState.codExclusaoPensionista
                  .toString()
                  .trim();
        }

        if (this.servidorPensionistaState.ocorrenciaExclusaoPensionista) {
          this.servidorPensionista.ocorrenciaExclusaoPensionista =
            this.servidorPensionistaState.ocorrenciaExclusaoPensionista === null
              ? null
              : this.servidorPensionistaState.ocorrenciaExclusaoPensionista
                  .toString()
                  .trim();
        }

        if (this.servidorPensionistaState.dataExclusaoPensionista) {
          this.servidorPensionista.dataExclusaoPensionista =
            this.servidorPensionistaState.dataExclusaoPensionista === null
              ? null
              : this.servidorPensionistaState.dataExclusaoPensionista
                  .toString()
                  .trim();
        }

        if (this.servidorPensionistaState.quotaParteEspolio) {
          this.servidorPensionista.quotaParteEspolio =
            this.servidorPensionistaState.quotaParteEspolio === null
              ? null
              : this.servidorPensionistaState.quotaParteEspolio
                  .toString()
                  .trim();
        }

        this.servidorSelectOption = this.servidoresFromPensionistaState;
        //this.servidorSelect = this.idServidor;
        this.getServidorSelectedLabel(this.idServidor);
      }
      this.isFetching = false;
      this.editionMode = true;
    },
    async loadPensionista() {
      if (
        typeof this.pensionistaSelected !== "undefined" &&
        this.pensionistaSelected !== "" &&
        this.pensionistaSelected !== null &&
        this.pensionistaSelected.cpfPensionista
      ) {
        this.loadservidorPensionista(null);
      } else {
        Swal.fire({
          title: "ATENÇÃO!",
          icon: "warning",
          text: "Por favor preencha o Cadastro antes de avançar as Etapadas !",
          customClass: {
            confirmButton: "md-button md-warning btn-fill"
          },
          buttonsStyling: false
        });
        window._Vue.$router
          .push({ name: "Novo Cadastro Pensionista" })
          .catch(err => {});
      }
    },
    async getEntidadeById(idEntidade) {
      try {
        let id = idEntidade;
        let papel = this.papel;
        let response = await this.ActionGetEntidadeById({ id, papel });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não encontrada")) {
              Swal.fire({
                title: `idEntidade: ${idEntidade} não encontrada.`,
                icon: "error",
                text: this.mensagem,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised md-danger btn-fill"
                },
                buttonsStyling: false
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `ERROR - idEntidade: ${idEntidade}`,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised mt-4 md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          } else {
            return response.body;
          }
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async getServidorFromPen() {
      let id = this.selectedPensionistaId;
      let papel = this.papel;
      try {
        let response = await this.ActionGetServidorFromPen({ id, papel }).then(
          response => {
            if (response.status === 200) {
              if (response.body.mensagem) {
                this.mensagem = response.body.mensagem;
                if (this.mensagem.includes("não encontrado")) {
                  Swal.fire({
                    title: `Não há pensão cadastrada`,
                    icon: "warning",
                    //text: `Não há pensão cadastrada`,
                    showCancelButton: true,
                    confirmButtonText: "ADICIONAR PENSÃO !",
                    cancelButtonText: "CANCELAR",
                    customClass: {
                      confirmButton: "md-button md-success md-raised mt-4",
                      cancelButton: "md-button md-danger md-raised mt-4"
                    },
                    buttonsStyling: false
                  }).then(result => {
                    if (result.value) {
                      this.isFetching = false;
                      this.addServidor(this.pensionistaSelected);
                      this.showButtonSalvar = true;
                    }
                  });
                  return response.body;
                } else {
                  Swal.fire({
                    icon: "error",
                    title: `ERROR - idPensionista: ${idPensionista}`,
                    text: `${this.mensagem}`,
                    customClass: {
                      confirmButton:
                        "md-button md-raised mt-4 md-success btn-fill"
                    },
                    buttonsStyling: false
                  });
                }
              } else {
                this.ActionSetServidoresFromPensionista(response.body);
                return response.body;
              }
            }
          }
        );
        return response;
      } catch (err) {
        this.handleError(err);
      }
    },
    async selectedServidor(idServidor) {
      if (idServidor !== null && idServidor !== "") {
        this.getServidorSelectedLabel(idServidor);
        this.idServidor = idServidor;
        let index = -1;
        let resultItem = this.servidoresFromPensionistaState.find(function(
          item,
          i
        ) {
          if (item.idServidor === idServidor) {
            index = i;
            return item;
          }
        });

        this.removeDraftServidorPensionista();
        //this.reload(resultItem.idServidor);
        this.loadservidorPensionista(resultItem.idServidor);
      }
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    async getPensionistaById(idPensionista, papelUsuario) {
      let id = idPensionista;
      let papel = papelUsuario;
      try {
        let response = await this.ActionGetPensionistaById({ id, papel });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não encontrado")) {
              Swal.fire({
                title: `idPensionista: ${idPensionista} não encontrado.`,
                icon: "error",
                text: this.mensagem,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised md-danger btn-fill"
                },
                buttonsStyling: false
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `ERROR - idPensionista: ${idPensionista}`,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised mt-4 md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          } else {
            return response.body;
          }
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    onSelect() {
      console.log("Nova Pensão");
    },
    addServidor(pensionista) {
      if (this.servidoresPensionistas.length > 0) {
        Swal.fire({
          title: "Você quer Salvar ?",
          text: `Você deseja adicionar uma nova Pensão e salvar a atual ?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, Salvar !",
          cancelButtonText: "CANCELAR",
          customClass: {
            confirmButton: "md-button md-success md-raised mt-4",
            cancelButton: "md-button md-danger md-raised mt-4"
          },
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            console.log(this.validaForm());
            let checkerror = true;
            this.$refs.observer.validate();
            this.delay(1000).then(() => {
              if (this.$refs.observer.errors) {
                checkerror = Object.keys(this.$refs.observer.errors).some(
                  key => {
                    let checkerrorArray =
                      this.$refs.observer.errors[key].length > 0 ? true : false;
                    return checkerrorArray;
                  }
                );
              }
              if (!checkerror) {
                this.showContactList = true;
                this.getServidorPublicoByEntidade(pensionista.idEntidade);
                if (this.servidoresPensionistas.length === 0) {
                  this.servidoresPensionistas.push(
                    Vue.util.extend({}, this.servidorPensionista)
                  );
                } else {
                  this.newServidorPensionista();
                  this.servidoresPensionistas.push(
                    Vue.util.extend({}, this.servidorPensionista)
                  );
                }
                this.ActionSetServidoresPensionistas(
                  this.servidoresPensionistas
                );
                this.reset();
                this.labelServidorSelect = "";
                this.servidorSelect = "";
              } else {
                Swal.fire({
                  icon: "error",
                  title: `Preencha os campos antes !`,
                  text: `Os campos devem ser satisfeitos com as condições e regras de cada um.`,
                  customClass: {
                    confirmButton:
                      "md-button md-raised mt-4 md-success btn-fill"
                  },
                  buttonsStyling: false
                });
              }
            });
          }
        });
      } else {
        this.showContactList = true;
        this.getServidorPublicoByEntidade(pensionista.idEntidade);
        this.servidoresPensionistas.push(
          Vue.util.extend({}, this.servidorPensionista)
        );
        this.ActionSetServidoresPensionistas(this.servidoresPensionistas);
      }
    },
    getIdSerPenFromServidorSelected(idServidor) {
      let index = -1;
      let resultItem = this.servidoresFromPensionistaState.find(function(
        item,
        i
      ) {
        if (item.idServidor === idServidor) {
          index = i;
          return item;
        }
      });
      return resultItem.idServPen;
    },
    getServidorSelectedLabel(idServidor) {
      let index = -1;
      let resultItem = this.servidorSelectOption.find(function(item, i) {
        if (item.idServidor === idServidor) {
          index = i;
          return item;
        }
      });
      if (typeof resultItem !== "undefined") {
        let label =
          "CPF: " +
          this.callCpfMask(resultItem.cpfServidor) +
          " || " +
          resultItem.nomeCompleto +
          " || " +
          "Siape: " +
          resultItem.siapeServidor;
        this.labelServidorSelect = label;
      } else {
        this.labelServidorSelect = "";
      }
    },

    async getServidorPensionistaById(idServPen) {
      let id = idServPen;
      let papel = this.papel;
      try {
        let response = await this.ActionGetServidorPensionistaById({
          id,
          papel
        });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não encontrado")) {
              Swal.fire({
                title: `Nenhuma relação encontrada entre este Servidor e este Pensionista.`,
                icon: "error",
                text: this.mensagem,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised md-danger btn-fill"
                },
                buttonsStyling: false
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `ERROR - idPensionista: ${idPensionista}`,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised mt-4 md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          } else {
            this.ActionSetServidorPensionista(response.body);
            return response.body;
          }
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    async getServidorPublicoByEntidade(idEntidade) {
      let id = idEntidade;
      let papel = this.papel;
      try {
        let response = await this.ActionGetServidorPublicoByEntidade({
          id,
          papel
        });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não encontrado")) {
              Swal.fire({
                title: `Nenhuma Servidor encontrado para esta Entidade.`,
                icon: "error",
                text: this.mensagem,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised md-danger btn-fill"
                },
                buttonsStyling: false
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `ERROR - idPensionista: ${idPensionista}`,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised mt-4 md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          } else {
            this.servidoresFromEntidade = response.body;
            this.forceRerender();
            return response.body;
          }
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    selectUfUpag(item) {
      let ufUpag = item;
      let index = -1;
      let resultItem = this.estados.find(function(item, i) {
        if (item.shortname === ufUpag) {
          index = i;
          return item;
        }
      });
      this.servidorPensionista.ufUpag = resultItem.shortname;
    },
    checkEditionDisabled() {
      if (this.isDisabledEdition === true) {
        return "disabled";
      } else {
        return null;
      }
    },
    async submit() {
      try {
        this.ActionSetServidoresPensionistas(this.servidoresPensionistas);
        let papel = this.papel;
        let servidoresPensionistas = this.servidoresPensionistas;
        this.removeDraftServidorPensionista();
        this.ActionSubmitPensionistaAllData({
          papel
        }).then(response => {
          checkAllResponsesPensionista(response);
        });
      } catch (err) {
        this.handleError(err);
      }
    },
    mdSelectScrollResizeCall: function() {
      mdSelectScrollResize();
    },
    emitToParent(value) {
      this.$emit("nextRoute", value);
    },
    cancelarButton() {
      let idServPen = this.servidorPensionista.idServPen;
      if (
        typeof idServPen !== "undefined" &&
        idServPen !== "" &&
        idServPen !== null
      ) {
        this.loadPensionista();
      } else {
        this.reset();
      }
    },
    removeDraftServidorPensionista() {
      let index = -1;
      let foundItem = this.servidoresPensionistas.find(function(item, i) {
        if (
          typeof item.servidorPublico === "undefined" ||
          item.servidorPublico === "" ||
          item.servidorPublico === null
        ) {
          index = i;
          return item;
        }
      });
      if (
        typeof foundItem !== "undefined" &&
        foundItem !== "" &&
        foundItem !== null
      ) {
        Vue.delete(this.servidoresPensionistas, index);
        this.ActionSetServidoresPensionistas(this.servidoresPensionistas);

        if (this.servidorSelectOption.length > 0) {
          this.servidorSelect = this.servidorSelectOption[0].idServidor;
        } else {
          this.reset();
          this.labelServidorSelect = "";
          this.servidorSelect = "";
        }
        this.showContactList = false;
      }
    },
    removeServidorPesionista(servidorPublico) {
      if (
        servidorPublico === "" ||
        servidorPublico === null ||
        typeof servidorPublico === "undefined"
      ) {
        if (this.servidorSelectOption.length === 0) {
          this.showContactList = false;
          this.reset();
          this.labelServidorSelect = "";
          this.servidorSelect = "";
        } else {
          this.removeDraftServidorPensionista();
        }
      } else {
        Swal.fire({
          title: "Você tem certeza ?",
          text: `Se você excluir este Servidor deste Pensionista apenas as informações da Pensão será excluida para este Pensionista!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, excluir !",
          cancelButtonText: "CANCELAR",
          customClass: {
            confirmButton: "md-button md-success md-raised mt-4",
            cancelButton: "md-button md-danger md-raised mt-4"
          },
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            console.log(servidorPublico);
            let index = -1;
            let foundItem = this.servidoresFromPensionistaState.find(function(
              item,
              i
            ) {
              if (item.idServidor === servidorPublico) {
                index = i;
                console.log(item);
                return item;
              }
            });
            if (
              typeof foundItem !== "undefined" &&
              foundItem !== "" &&
              foundItem !== null
            ) {
              if (this.servidorSelectOption.length > 0) {
                Vue.delete(this.servidoresFromPensionistaState, index);
                let servidoresFromPensionistaState = this
                  .servidoresFromPensionistaState;
                this.ActionSetServidoresFromPensionista(
                  servidoresFromPensionistaState
                );
                let index2 = -1;
                let foundItem2 = this.servidoresPensionistas.find(function(
                  item,
                  i
                ) {
                  if (item.servidorPublico === servidorPublico) {
                    index2 = i;
                    return item;
                  }
                });
                Vue.delete(this.servidoresPensionistas, index2);

                this.ActionSetServidoresPensionistas(
                  this.servidoresPensionistas
                );
                this.servidoresPensionistas = this.servidoresPensionistasState;
                this.servidorSelectOption = this.servidoresFromPensionistaState;
                if (this.servidoresFromPensionistaState.length > 0) {
                  this.reset();
                  this.servidorSelect = this.servidorSelectOption[0].idServidor;
                  this.showContactList = false;
                  //this.reload(this.servidorSelectOption[0].idServidor);
                } else {
                  this.showContactList = false;
                  this.reset();
                  this.labelServidorSelect = "";
                  this.servidorSelect = "";
                }
              }
            } else {
              this.showContactList = false;
              this.ActionSetServidoresFromPensionista([]);
              this.servidorSelectOption = this.servidoresFromPensionistaState;
              this.reset();
              this.labelServidorSelect = "";
              this.servidorSelect = "";
            }
          }
        });
      }
    },
    newServidorPensionista() {
      this.servidorPensionista = {
        idServPen: "",
        pensionista: "",
        servidorPublico: "",
        idEntidade: "",
        nomeEntidade: "",
        siapePensionista: "",
        siapePensionistaDv: "",
        parentesco: "",
        upag: "",
        upagDescricao: "",
        siglaUpag: "",
        ufUpag: "",
        tipoPensao: "",
        ocorrenciaPensao: "",
        naturezaPensao: "",
        numeroProcessoPensao: "",
        dataInicioPensao: "",
        dataFimPensao: "",
        codExclusaoPensionista: "",
        grupoExcPensionista: "",
        ocorrenciaExclusaoPensionista: "",
        quotaParteEspolio: "",
        dataExclusaoPensionista: ""
      };
    },
    reset() {
      this.newServidorPensionista();
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
      this.ufUpagSelect = "";
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    forceRerender2() {
      this.componentKeyToRerender2 += 1;
    },
    validarProximo() {
      let checkerror = true;
      this.$refs.observer.validate();
      this.delay(1000).then(() => {
        if (this.$refs.observer.errors) {
          checkerror = Object.keys(this.$refs.observer.errors).some(key => {
            let checkerrorArray =
              this.$refs.observer.errors[key].length > 0 ? true : false;
            return checkerrorArray;
          });
          if (!checkerror) {
            /*this.servidoresPensionistas.push(
              Vue.util.extend({}, this.servidorPensionista)
            );*/
            this.ActionSetServidoresPensionistas(this.servidoresPensionistas);
            this.emitToParent("enderecos");
            window._Vue.$router
              .push({ name: "Novos Enderecos Pensionista" })
              .catch(err => {});
          }
        }
      });
    }
  },
  watch: {
    "servidorPensionista.siapePensionista": function(val, oldVal) {
      if (val !== null && val.length > 0) {
        let siape = siapePensionistaMask(val.toString());
        if (siape.length >= 9) {
          siape = siape.substring(0, siape.length - 1);
          this.forceRerender2();
        }
        console.log(siape.length);
        if (siape.length === 0) {
          console.log(siape.length);
        }
        console.log(siape);
        this.servidorPensionista.siapePensionista = siape;
      }
    },
    servidorOrPensionistaSelected: function(val, oldVal) {
      this.showContactList = false;
      this.idServidor = val.id;
      let label =
        "CPF: " +
        this.callCpfMask(val.cpf) +
        " || " +
        val.nomeCompleto +
        " || " +
        "Siape: " +
        val.siape;
      this.labelServidorSelect = label;
      let papel = this.papel;
      this.showButtonSalvar = true;
      this.ActionGetServidorPublicoById({
        id: val.id,
        papel
      }).then(response => {
        let index = -1;
        let id = this.idServidor;
        let foundItem = this.servidoresPensionistas.find(function(item, i) {
          if (item.servidorPublico === id) {
            index = i;
            return item;
          }
        });
        if (
          foundItem !== null &&
          foundItem !== "" &&
          typeof foundItem === "undefined"
        ) {
          let servidores = this.servidoresFromPensionistaState;
          servidores.push(Vue.util.extend({}, response.body));
          this.ActionSetServidoresFromPensionista(servidores);
          //this.servidorSelect = val.id;
          this.reset();
          if (this.servidorSelectOption.length === 0) {
            this.servidorSelectOption = this.servidoresFromPensionistaState;
          }
          this.servidorPensionista.servidorPublico = response.body.idServidor;

          Object.keys(this.servidoresPensionistas).map(key => {
            console.log(this.servidoresPensionistas[key].servidorPublico);
            if (
              typeof this.servidoresPensionistas[key].servidorPublico ===
                "undefined" ||
              this.servidoresPensionistas[key].servidorPublico === "" ||
              this.servidoresPensionistas[key].servidorPublico === null
            ) {
              return (this.servidoresPensionistas[
                key
              ].servidorPublico = this.servidorPensionista.servidorPublico);
            }
          });
          this.servidorSelect = val.id;
        } else {
          Vue.delete(
            this.servidoresPensionistas,
            this.servidoresPensionistas.length - 1
          );
          this.ActionSetServidoresPensionistas(this.servidoresPensionistas);
          this.servidorSelect = val.id;
        }
      });
    },
    "servidorPensionista.dataInicioPensao": function(val, oldVal) {
      if (typeof this.servidorPensionista !== "undefined") {
        this.servidorPensionista.dataInicioPensao =
          val === null || val === "" ? null : dataMask(val.toString());
      }
    },
    "servidorPensionista.dataFimPensao": function(val, oldVal) {
      if (typeof this.servidorPensionista !== "undefined") {
        this.servidorPensionista.dataFimPensao =
          val === null ? null : dataMask(val.toString());
      }
    },
    "servidorPensionista.dataExclusaoPensionista": function(val, oldVal) {
      if (typeof this.servidorPensionista !== "undefined") {
        this.servidorPensionista.dataExclusaoPensionista =
          val === null ? null : dataMask(val.toString());
      }
    },
    "servidorPensionista.quotaParteEspolio": function(val, oldVal) {
      if (typeof this.servidorPensionista !== "undefined") {
        this.servidorPensionista.quotaParteEspolio =
          val === null || val === "" ? null : val.toString().replace(",", ".");
      }
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

#servidorSelected {
  color: black;
  font-weight: 300;
  font-size: 0.7rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
::v-deep .modal-container {
  max-width: 720px;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-count {
  display: none;
}

#agrupamentoCampos {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}

::v-deep .md-radio.md-disabled .md-radio-label,
.md-radio.md-disabled .md-radio-container {
  opacity: 1;
  border-color: rgba(0, 0, 0, 1) !important;
}

::v-deep .md-input {
  text-transform: uppercase;
}

::v-deep .md-switch-label {
  font-weight: bold;
  color: rgba(14, 14, 14, 0.52);
}

::v-deep .md-switch.md-checked .md-switch-container {
  background-color: #49a54d !important;
}

::v-deep .md-switch.md-checked .md-switch-thumb {
  border: 1px solid #49a54d;
}

::v-deep .md-radio.md-checked .md-radio-container {
  border-color: #000000 !important;
}

::v-deep .md-radio.md-checked .md-radio-container:after {
  background-color: #000000 !important;
}

.switch-div {
  /*display: inline-block; */
  float: right;
}

.list-modal {
  width: 100%;
}

.md-ripple > span {
  position: relative;
}

.md-list {
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

::v-deep .modal-body.text-center {
  height: 350px;
}

.listContact.modal-body.text-center {
  height: 350px;
}

.md-list-item-container {
  font-size: 12px;
}

::v-deep .md-list-item-text {
  font-size: 10px;
}

input .md-input.md-input.md-select-value {
  font-size: 10px;
}
</style>
