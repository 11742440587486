<template>
  <div
    class="contactDetail__container"
    v-bind:value="servidorPensionistaClicado"
    @click="catchServidorOrPensionistaData(nomeCompleto, cpf, siape, id)"
  >
    <!-- <div
      class="avatar__wrapper"
      v-bind:value="nomeClicado"
      @click="catchName(primeiroNome, sobrenome)"
    ></div>-->
    <div class="contact__text">
      <div class="contact__name">
        <text-highlight :queries="queries">{{ nomeCompleto }}</text-highlight
        >&nbsp;
      </div>
      <div>
        <text-highlight :queries="queries">cpf: {{ cpf }}</text-highlight>
      </div>
      <div>
        <text-highlight :queries="queries">siape: {{ siape }}</text-highlight>
      </div>
      <div class="contact__words">
        <text-highlight :queries="queries">id: {{ id }}</text-highlight>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  props: {
    id: String,
    nomeCompleto: String,
    cpf: String,
    siape: String,
    search: String,
    nomeClicado: String
  },
  data() {
    return {
      servidorPensionistaClicado: null
    };
  },
  methods: {
    ...mapActions("servidor_pensionista", [
      "ActionSetServidorOrPensionistaSelected"
    ]),
    catchServidorOrPensionistaData(nomeCompleto, cpf, siape, id) {
      this.ActionSetServidorOrPensionistaSelected({
        nomeCompleto: nomeCompleto,
        cpf: cpf,
        siape: siape,
        id: id
      });
      this.$emit("input", {
        nomeCompleto: nomeCompleto,
        cpf: cpf,
        siape: siape,
        id: id
      });
    }
  },
  computed: {
    ...mapState("servidor_pensionista", ["servidorOrPensionistaSelected"]),
    queries() {
      return this.search.split(/\s+/);
    }
  }
};
</script>

<style lang="scss" scoped>
.contactDetail__container {
  cursor: pointer;
  height: 105px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  padding: 10px;
  margin: 5px 15px 15px 15px;
  border-radius: 5px;
  box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.1);
  background: white;
  .avatar__wrapper {
    width: 55px;
    height: 55px;
    margin: 5px;
    .contact__avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
  .contact__name {
    font-weight: 600;
    color: #42b983;
  }
  .contact__text {
    text-align: left;
    padding: 15px;
  }
  .contact__words {
    font-size: 11px;
  }
}
</style>
